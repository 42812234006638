import React, { useState, useEffect } from 'react';
import { PageBody, TitlePage } from "./components/C_Page";
import axios from 'axios';
import { ClassementPage, InfoPlayer, PlayerInfos, PlayerInfosTemplate } from './components/C_Classement';
import { Helmet } from 'react-helmet';

const Player = ({rank, name, elo, matchs_played, avg_points, image}) => {
    return (
        <PlayerInfos>
            <InfoPlayer>{rank}</InfoPlayer>
            <InfoPlayer>{name}</InfoPlayer>
            <InfoPlayer>{elo}</InfoPlayer>
            <InfoPlayer>{matchs_played}</InfoPlayer>
            <InfoPlayer>{avg_points}</InfoPlayer>
        </PlayerInfos>
    );
};

const PlayerTemplate = ({rank, name, elo, matchs_played, avg_points, image}) => {
    return (
        <PlayerInfosTemplate>
            <InfoPlayer>{rank}</InfoPlayer>
            <InfoPlayer>{name}</InfoPlayer>
            <InfoPlayer>{elo}</InfoPlayer>
            <InfoPlayer>{matchs_played}</InfoPlayer>
            <InfoPlayer>{avg_points}</InfoPlayer>
        </PlayerInfosTemplate>
    );
};

const Classement = () => {
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        axios.get('https://shell-society-api.onrender.com/getPlayers')
            .then((response) => {
                const sortedPlayers = response.data.players.sort((a, b) => b.elo - a.elo);
                const playersWithRank = sortedPlayers.map((player, index) => ({
                    ...player,
                    rank: index + 1,
                }));
                setPlayers(playersWithRank);
            })
            .catch((error) => {
                console.error('Error fetching players:', error);
            });
    }, []);
    
    return (
        <>
            <Helmet>
                <title>Classement - Shell Society</title>
            </Helmet>
            <PageBody>
                <TitlePage>Classement</TitlePage>
                <ClassementPage>
                    <PlayerTemplate
                        rank={"Rank"}
                        name={"Name"}
                        elo={"ELO"}
                        matchs_played={"Matchs played"}
                        avg_points={"Average points"}
                    />
                    {players.map((player, index) => (
                        <Player
                            key={index}
                            rank={player.rank}
                            name={player.name}
                            elo={player.elo}
                            matchs_played={player.matchs_played}
                            avg_points={player.avg_points}
                            image={player.picture}
                        />
                    ))}
                </ClassementPage>
            </PageBody>
        </>
    );
};

export default Classement;