import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSwipeable } from 'react-swipeable';
import { InfoPlayerMobile, PlayerInfosMobile, PlayerInfosTemplateMobile } from './components/C_ClassementMobile';


const PlayerMobile = ({rank, name, elo}) => {
    return (
        <PlayerInfosMobile>
            <InfoPlayerMobile>{rank}</InfoPlayerMobile>
            <InfoPlayerMobile>{name}</InfoPlayerMobile>
            <InfoPlayerMobile>{elo}</InfoPlayerMobile>
        </PlayerInfosMobile>
    );
};

const PlayerTemplateMobile = ({rank, name, elo}) => {
    return (
        <PlayerInfosTemplateMobile>
            <InfoPlayerMobile>{rank}</InfoPlayerMobile>
            <InfoPlayerMobile>{name}</InfoPlayerMobile>
            <InfoPlayerMobile>{elo}</InfoPlayerMobile>
        </PlayerInfosTemplateMobile>
    );
};


const ClassementMobile = () => {
    const [players, setPlayers] = useState([]);
    useEffect(() => {
        axios.get('https://shell-society-api.onrender.com/getPlayers')
            .then((response) => {
                const sortedPlayers = response.data.players.sort((a, b) => b.elo - a.elo);
                const playersWithRank = sortedPlayers.map((player, index) => ({
                    ...player,
                    rank: index + 1,
                }));
                setPlayers(playersWithRank);
            })
            .catch((error) => {
                console.error('Error fetching players:', error);
            });
    }, []);
    const [windowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [index, setIndex] = useState(0);

    const handleNext = () => {
        setIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
    };

    const handlePrev = () => {
        setIndex((prevIndex) => (prevIndex === 0 ? 2 : prevIndex - 1));
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleNext(),
        onSwipedRight: () => handlePrev(),
    });


    const trophyHeight = windowSize.height * 0.15;

    return (
        <div style={{overflow: 'hidden', flex: 1, alignItems: 'center', backgroundColor: '#082026',maxHeight: '100vh', height: '100vh', display: 'flex', flexDirection: 'column' }} {...handlers}>
            {index === 0 && (
                <div style={{ position: 'relative'}}>
                    <div
                        style={{
                            position: 'absolute',
                            marginTop: window.innerHeight * 0.15,
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '60px',
                            fontFamily: 'Righteous',
                            fontWeight: 400,
                            color: '#FFFD70',
                        }}
                        >
                        Classement
                    </div>
                    <div
                        style={{textAlign: 'center'}}>
                        <img
                        style={{
                            marginTop: window.innerHeight * 0.2,
                            width: 'auto',
                            height: `${trophyHeight}px`,
                        }}
                        src="trophy.png"
                        alt="trophy.png"
                        />
                    </div>
                    <div
                        style={{textAlign: 'center', overflow: 'auto', maxHeight: '500px'}}>
                        <PlayerTemplateMobile
                            rank={"Rank"}
                            name={"Name"}
                            elo={"ELO"}
                            matchs_played={"Matchs played"}
                            avg_points={"Average points"}
                        />
                        {players.map((player, index) => (
                            <PlayerMobile
                                key={index}
                                rank={player.rank}
                                name={player.name}
                                elo={player.elo}
                                matchs_played={player.matchs_played}
                                avg_points={player.avg_points}
                                image={player.picture}
                            />
                        ))}
                    </div>
                </div>
                )}
                {index === 1 && (
                <div style={{ position: 'relative', textAlign: 'center' }}>
                    <div
                    style={{
                        position: 'absolute',
                        marginTop: window.innerHeight * 0.15,
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '60px',
                        fontFamily: 'Righteous',
                        fontWeight: 400,
                        color: '#88D23F',
                    }}
                    >
                    GreenShell
                    </div>
                    <img
                    style={{
                        marginTop: window.innerHeight * 0.2,
                        width: 'auto',
                        height: `${trophyHeight}px`,
                    }}
                    src="trophy.png"
                    alt="trophy.png"
                    />
                </div>
                )}
                {index === 2 && (
                <div style={{ position: 'relative', textAlign: 'center' }}>
                    <div
                    style={{
                        position: 'absolute',
                        marginTop: window.innerHeight * 0.15,
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '60px',
                        fontFamily: 'Righteous',
                        fontWeight: 400,
                        color: '#FB2A2A',
                    }}
                    >
                    RedShell
                    </div>
                    <img
                    style={{
                        marginTop: window.innerHeight * 0.2,
                        width: 'auto',
                        height: `${trophyHeight}px`,
                    }}
                    src="trophy.png"
                    alt="trophy.png"
                    />
                </div>
                )}
            <div   div style={{   flex: 1, 
                        display: 'flex',
                        position: 'fixed',
                        bottom: '0',
                        padding: '8px',
                        justifyContent: 'center',
                        textAlign: 'center',
                        marginBottom: '10px'}}>
            {[0, 1, 2].map((pageIndex) => (
                <div
                    key={pageIndex}
                    style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: index === pageIndex ? '#fff' : '#ccc',
                        margin: '0 5px',
                    }}
                ></div>
            ))}
        </div>
    </div>
    );
};

export default ClassementMobile;
