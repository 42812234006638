import React, { useState, useEffect } from 'react';
import axios from "axios";
import { PageBody, TitlePage } from "./components/C_Page";
import {
    PlayerInfos,
    InfoPlayer,
    PlayerInfosTemplate,
} from "./components/C_Classement";
import { TournamentName, TournamentPage } from './components/C_GreenShell';
import { Helmet } from 'react-helmet';

const TournamentPlayer = ({rank, name, points, matchResults, image}) => {
    const remainingSlots = 12 - matchResults.length;

    return (
        <PlayerInfos>
            <InfoPlayer>{rank}</InfoPlayer>
            <InfoPlayer>{name}</InfoPlayer>
            <InfoPlayer>{points}</InfoPlayer>
            {matchResults.map((result, index) => (
                <InfoPlayer key={index}>{result}</InfoPlayer>
            ))}
            {[...Array(remainingSlots)].map((_, index) => (
                <InfoPlayer key={index + matchResults.length}>-</InfoPlayer>
            ))}
        </PlayerInfos>
    );
};

const TournamentPlayerTemplate = ({rank, name, points, image}) => {
    return (
        <PlayerInfosTemplate>
            <InfoPlayer>{rank}</InfoPlayer>
            <InfoPlayer>{name}</InfoPlayer>
            <InfoPlayer>{points}</InfoPlayer>
            {[...Array(12)].map((_, index) => (
                <InfoPlayer key={index}>Match {index + 1}</InfoPlayer>
            ))}
        </PlayerInfosTemplate>
    );
};

const Tournament = ({name, players}) => {
    const [playerDetails, setPlayerDetails] = useState([]);

    useEffect(() => {
        const fetchPlayerDetails = async () => {
            const details = [];
            for (const player of players) {
                try {
                    const response = await axios.get(`https://shell-society-api.onrender.com/getOnePlayer?id=${player}`);
                    details.push(response.data.player);
                } catch (error) {
                    console.error(`Error fetching player ${player}:`, error);
                }
            }
    
            // Sort based on currentTournament.points
            const playersWithRank = details.map(player => {
                const currentTournament = player['green-shells']?.find(tournament => tournament.name === name);
                return {
                    ...player,
                    points: currentTournament ? currentTournament.points : 0
                };
            }).sort((a, b) => b.points - a.points)
            .map((player, index) => ({
                ...player,
                rank: index + 1,
            }));
    
            setPlayerDetails(playersWithRank);
        };
        fetchPlayerDetails();
    }, [name, players]);    

    return (
        <TournamentPage>
            <TournamentName>{name}</TournamentName>
            <TournamentPlayerTemplate
                rank={"Rank"}
                name={"Name"}
                points={"Points"}
            />
            {playerDetails.map((player, index) => {
                const currentTournament = player['green-shells']?.find(tournament => tournament.name === name);
                const matchResults = currentTournament?.matchs || [];
                return (
                    <TournamentPlayer
                        key={index}
                        rank={player.rank}
                        name={player.name}
                        points={currentTournament.points}
                        image={player.picture}
                        matchResults={matchResults}
                    />
                );
            })}
        </TournamentPage>
    );
};

const GreenShell = () => {
    const [tournaments, setTournaments] = useState([]);

    useEffect(() => {
        axios.get('https://shell-society-api.onrender.com/getGreenShell')
            .then((response) => {
                setTournaments(response.data.tournaments)
            })
            .catch((error) => {
                console.error('Error fetching tournaments:', error);
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>GreenShell - Shell Society</title>
            </Helmet>
            <PageBody>
                <TitlePage>GreenShell</TitlePage>
                {tournaments.map((tournament, index) => (
                    <Tournament
                        key={index}
                        name={tournament.name}
                        players={tournament.players}
                    />
                ))}
            </PageBody>
        </>
    );
};

export default GreenShell;