import React from "react";
import { ExternalLink, Foot, TextFoot, TitleFoot } from "./components/C_Foot";
import { Inscription, InscriptionButton, TitleInscription } from "./components/C_Inscription";

const Footer = () => {
    return (
        <div>
            <Inscription>
                <TitleInscription>Envie de rejoindre la communauté ?</TitleInscription>
                <InscriptionButton to={'/profil'}>Je m'inscris !</InscriptionButton>
            </Inscription>
            <Foot>
                <TitleFoot>© ShellSprint Society</TitleFoot>
                <TextFoot>Nintendo is not a sponsor of or affiliated with this tournament.</TextFoot>
                <TextFoot>Terms for participating in and viewing Community Tournaments using Nintendo Games. More informations <ExternalLink href="https://en-americas-support.nintendo.com/app/answers/detail/a_id/63454">here</ExternalLink>.</TextFoot>
            </Foot>
        </div>
    );
};

export default Footer;