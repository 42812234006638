import styled from "styled-components";
import { background_color, green_main_color } from "../../globals";

export const Foot = styled.div`
    padding: 5% 2.5%;
    background: ${green_main_color};
    bottom: 0;
    width: 95%;
    box-shadow: 0 1px 10px ${green_main_color};
 
    @media (max-width: 1000px) {
        // padding: 70px 30px;
    }
`;

export const TitleFoot = styled.h1`
    text-align: center;
    color: ${background_color};
`;

export const TextFoot = styled.p`
    text-align: center;
    color: ${background_color}
`;

export const ExternalLink = styled.a`
    color: ${background_color};

`;