import styled from "styled-components";

import { shadow_color } from "../../globals";

export const TournamentPage = styled.div`
    width: 60%;
    margin: auto;
    border: solid 3px black;
    border-radius: 30px;
    background: ${shadow_color};
    margin-bottom: 50px;
`;

export const TournamentName = styled.h2`
    text-align: center;
`;