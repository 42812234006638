import React from "react";

import {
    NavBtn,
    NavBtnLink,
} from './components/C_Navbar'

const NavbarButtons = () => {
    return (
        <NavBtn>
            <NavBtnLink to='/profil'>Profil</NavBtnLink>
        </NavBtn>
    );
};

export default NavbarButtons;