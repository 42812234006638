import styled from "styled-components";
import { background_color, green_main_color, shadow_color } from "../../globals";

export const PlayerInfos = styled.div`
    border-top: solid 2px black;
    display: flex;
    color: ${green_main_color};
    text-align: center;
`;

export const PlayerInfosTemplate = styled(PlayerInfos)`
    border-top: none;
    font-weight: bold;
    color: ${background_color}
`;

export const ClassementPage = styled.div`
    width: 60%;
    margin: auto;
    border: solid 3px black;
    border-radius: 30px;
    background: ${shadow_color};
`;

export const InfoPlayer = styled.p`
    width: 100px;
`;
