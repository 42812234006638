import styled from "styled-components";
import { ClickableImage } from "../../Header/components/C_Logo";
import { background_color, blue_background } from "../../globals";

export const ImageBlock = styled(ClickableImage)`
    margin: auto;
`;

export const Block = styled.div`
    background: ${blue_background};
    width: 60%;
    border-radius: 10%;
    margin: auto;
`;

export const BlockTitle = styled.h1`
    color: ${background_color};
    text-align: center;
    padding-top: 30px;
`;

export const Paragraph = styled.p`
    color: ${background_color};
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-algin: center;
    font-size: 18px;
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;    
`;

export const HomePage = styled.div`
`;