import styled from "styled-components";
import { background_color, shadow_color } from "../../globals";

export const Head = styled.div`
    background: ${background_color};
    box-shadow: 0 1px 10px ${shadow_color};
    height: 85px;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem calc((100vw - 1500px) / 2);
    z-index: 12;
`;