import { PageBody, TitlePage } from "./components/C_Page";
import {
    ImageBlock,
    Block,
    BlockTitle,
    Paragraph,
    Content,
    HomePage
} from "./components/C_Home";
import {
    Link
} from 'react-router-dom';

import { Helmet } from "react-helmet";
import React, { useState, useEffect } from 'react';


const GreenShell = () => {
    return (
        <Block>
            <BlockTitle>Green Shell</BlockTitle>
            <Content>
                <ImageBlock source="green_shell_250.png" link={"/green-shell/"} />
                <Paragraph>
                    Rejoignez-nous chaque mardi soir pour une expérience palpitante avec le tournoi Green Shell de Mario Kart 8 Deluxe. Situé dans l'agréable espace chill d'Epitech, ce tournoi hebdomadaire offre une opportunité unique de s'affronter en équipe de deux, avec une petite touche stratégique grâce au friendly fire activé. Que vous soyez un compétiteur acharné ou un joueur occasionnel, Green Shell est l'endroit parfait pour tester vos compétences en karting, rencontrer de nouvelles personnes et surtout, passer un bon moment. N'importe qui est le bienvenu, alors venez comme vous êtes et préparez-vous à vivre des courses mémorables !
                </Paragraph>
            </Content>
        </Block>
    );
};

const RedShell = () => {
    return (
        <Block>
            <BlockTitle>Red Shell</BlockTitle>
            <Content>
                <ImageBlock source="red_shell_250.png" link={"/red-shell/"} />
                <Paragraph>
                Le tournoi Red Shell est la nouvelle sensation mensuelle pour les amateurs de Mario Kart 8 Deluxe. Inspiré par le succès de notre tournoi hebdomadaire Green Shell, Red Shell promet une expérience encore plus grande et plus inclusive. Cette fois, nous ouvrons les portes à un nombre beaucoup plus important de participants, offrant ainsi une plateforme idéale pour que les joueurs de tous horizons se rencontrent et s'affrontent dans un esprit amical mais compétitif. Avec des prix en jeu pour les gagnants, Red Shell n'est pas seulement une occasion de s'amuser et de socialiser, mais aussi une chance de montrer votre talent et de repartir avec des récompenses. Que vous soyez un vétéran de Mario Kart ou un débutant enthousiaste, Red Shell vous accueille pour une aventure palpitante sur la piste. Préparez-vous pour des courses pleines d'action, des virages serrés et des moments inoubliables !
                </Paragraph>
            </Content>
        </Block>
    );
};


const Home = () => {
    const [windowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const [isMobile, setIsMobile] = useState(window.innerWidth < 720)

    const handleResize = () => {
        setIsMobile(window.innerWidth < 720)
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const buttonHeight = windowSize.height * 0.15;
    const buttonMargin = windowSize.height * 0.1;
    return (
        <>
            {isMobile ? (
                <>
                    <PageBody style={{backgroundColor: '#082026',
                    height: '100vh'}}>
                        <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'}}>
                            <img
                                style={{
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    marginTop: window.innerHeight * 0.1,
                                    width: window.innerWidth * 0.33,
                                    height: window.innerWidth * 0.33,
                                }}
                                src="logo_test2.png"
                                alt="logo_test2.png"
                            />
                        </div>
                        <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'}}>
                            <h1 style={{
                                    fontSize: '20px', // Taille de la police
                                    fontFamily: 'Righteous', // Famille de polices
                                    fontWeight: 400, // Poids de la police
                                    color: '#FFFFFF', // Couleur du texte
                                    letterSpacing: '15px', // Espacement des lettres
                                }}>
                                    ShellSociety
                            </h1>
                        </div>
                        <h1 style={{
                            marginTop: '-10px',
                            fontSize: '15px', // Taille de la police
                            fontFamily: 'Righteous', // Famille de polices
                            fontWeight: 400, // Poids de la police
                            color: '#FFFFFF', // Couleur du texte
                            textAlign: 'center', // Alignement du texte
                            textDecoration: 'underline' // Soulignement du texte
                        }}>
                            Qui sommes nous ?
                        </h1>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'}}>
                            <Link to="/classement-mobile"
                                style={{
                                    width: '80%',
                                    textAlign: 'center',
                                }}>
                                <button style={{// Largeur du bouton (prend tout l'espace en largeur)
                                    width: '100%',
                                    height: `${buttonHeight}px`, // Hauteur du bouton
                                    backgroundColor: '#D9D9D9', // Couleur de fond du bouton
                                    borderRadius: '20px', // Rayon des coins arrondis du bouton
                                    border: 'none', // Supprime la bordure par défaut du bouton
                                    fontFamily: 'Righteous',
                                    fontWeight: 400, // Poids de la police
                                    color: '#000000',
                                    marginTop: `${buttonMargin}px`, // Marge supérieure du bouton
                                    marginBottom: `${buttonMargin}px`, // Marge inférieure du bouton
                                    fontSize: '50px', // Taille de la police du texte du bouton // Centrage du texte
                                }}>
                                    ENTRER
                                </button>
                            </Link>
                        </div>
                        <div>
                        <div
                            style={{
                                position: 'fixed',
                                bottom: '0',
                                left: '0',
                                padding: '8px',
                                justifyContent: 'center',
                                textAlign: 'center',
                                marginBottom: '10px',
                            }}>
                            <div style={{
                                textAlign: 'center',
                                fontSize: '10px',
                                color: '#808080',
                            }}>
                                Nintendo is not a sponsor of or affiliated with this tournament
                            </div>
                            <div style={{
                                fontSize: '8px',
                                color: '#808080',
                            }}>
                                Terms for participating in and viewing Community Tournaments using Nintendo games
                                https://www.nintendo.co.uk/Legal-information/Terms-for-participating-in-and-viewing-Community-Tournaments-using-Nintendo-games-for-all-Participants-and-Spectators--2468907.html
                            </div>
                        </div>
                    </div>

                    </PageBody>
                </>
            ) : (
                <>
                    <Helmet>
                        <input>Shell Society</input>
                    </Helmet>
                    <PageBody>
                        <TitlePage>Home</TitlePage>
                        <HomePage>
                            <GreenShell />
                            <RedShell />
                        </HomePage>
                    </PageBody>
                </>
            )}
        </>
    );
};

export default Home;