import React from "react";

import {
    Head
} from "./components/C_Head";

import Navbar from "./Navbar";
import NavbarButtons from "./NavbarButtons";
import Logo from "./Logo";

const Header = () => {
    return (
        <Head>
            <Logo />
            <Navbar />
            <NavbarButtons />
        </Head>
    );
};

export default Header;