import React from "react";
import { PageBody } from "./components/C_Page";
import { DetailsMessage, ErrorMessage } from "./components/C_Error";
import { Helmet } from "react-helmet";

const BuildingPage = () => {
    return (
        <>
            <Helmet>
                <title>Soon - Shell Society</title>
            </Helmet>
            <PageBody>      
                <ErrorMessage>Building...</ErrorMessage>
                <DetailsMessage>This page will be finish soon.</DetailsMessage>
            </PageBody>
        </>
    );
};

export default BuildingPage;