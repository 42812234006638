import styled from "styled-components";
import { background_color, shadow_color } from "../../globals";

export const PlayerInfosMobile = styled.div`
    display: flex;
    color: #FFFFFF;
    text-align: center;
`;

export const PlayerInfosTemplateMobile = styled(PlayerInfosMobile)`
    border-top: none;
    font-weight: bold;
    color: ${background_color}
`;

export const ClassementPageMobile = styled.div`
    width: 60%;
    margin: auto;
    border: solid 3px black;
    border-radius: 30px;
    background: ${shadow_color};
`;

export const InfoPlayerMobile = styled.p`
    margin: 30px;
    width: 50px;
`;
