import styled from "styled-components";
import { green_main_color } from "../../globals";

export const ErrorMessage = styled.h1`
    font-size: 150px;
    color: ${green_main_color};
    text-align: center;
`;

export const DetailsMessage = styled.p`
    text-align: center;
`;