import React from "react";

import { ClickableImage } from "./components/C_Logo";

const Logo = () => {
    return (
        <ClickableImage source="logo_test2.png" link="/" />
    );
};

export default Logo;