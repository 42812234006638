import './App.css';


import React from 'react';

import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import { MobileView, BrowserView } from 'react-device-detect';

import Header from './Header/Header';
import Footer from './Footer/Footer';

import GreenShell from './Pages/GreenShell';
import GreenShellMobile from './Pages/GreenShellMobile';
import RedShellMobile from './Pages/RedShellMobile';
// import RedShell from './Pages/RedShell';
import Home from './Pages/Home';
import Classement from './Pages/Classement';
import ClassementMobile from './Pages/ClassementMobile';
// import Profil from './Pages/Profil';
import Error404 from './Pages/404';
import BuildingPage from './Pages/Building';
function App() {

  return (
    <>
        <MobileView>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Error404 />} />
              <Route path="/" element={<Home />} />
              <Route path="/green-shell" element={<GreenShell />} />
              <Route path="/red-shell" element={<BuildingPage />} />
              <Route path="/profil" element={<BuildingPage />} />
              <Route path="/classement-mobile" element={<ClassementMobile />} />
              <Route path="/green-shell-mobile" element={<GreenShellMobile />} />
              <Route path="/red-shell-mobile" element={<RedShellMobile />} />
              <Route path="/404" element={<Error404 />} />
            </Routes>
          </BrowserRouter>
        </MobileView>
        <BrowserView>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route path="*" element={<Error404 />} />
              <Route path="/" element={<Home />} />
              <Route path="/green-shell" element={<GreenShell />} />
              <Route path="/red-shell" element={<BuildingPage />} />
              <Route path="/profil" element={<BuildingPage />} />
              <Route path="/classement" element={<Classement />} />
              <Route path="/404" element={<Error404 />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </BrowserView>
    </>
  );
}


export default App;
