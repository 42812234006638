import React from "react";

import {
    NavMenu,
    NavLink
} from "./components/C_Navbar";

const Navbar = () => {
    return (
        <NavMenu>
            <NavLink to={'/'}>Home</NavLink>
            <NavLink to={'/green-shell'}>Green Shell</NavLink>
            <NavLink to={'/red-shell'}>Red Shell</NavLink>
            <NavLink to={'/classement'}>Classement</NavLink>
        </NavMenu>
    );
};

export default Navbar;