import styled from "styled-components";
import { background_color, green_main_color, green_secondary_color } from "../../globals";
import { NavLink as Link } from "react-router-dom";

export const Inscription = styled.div`
    padding: 5% 2.5%;
    background: ${green_secondary_color};
    bottom: 0;
    width: 95%;
    box-shadow: 0 1px 10px ${green_secondary_color};
    text-align: center;
 
    @media (max-width: 1000px) {
        // padding: 70px 30px;
    }
`;

export const TitleInscription = styled.h1`
    color: ${background_color};
`;

export const InscriptionButton = styled(Link)`
  border-radius: 4px;
  background: ${background_color};
  padding: 10px 22px;
  color: ${green_main_color};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${green_main_color};
    color: ${background_color};
  }
`;