import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

import { green_main_color, green_secondary_color, background_color } from "../../globals";

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
`;

export const NavLink = styled(Link)`
    color: ${green_main_color};
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    &.active {
        color: ${green_secondary_color};
    }
`;

export const NavBtn = styled.nav` 
  display: flex;
  align-items: center;
  margin-right: 24px;
`;
  
export const NavBtnLink = styled(Link)` 
  border-radius: 4px;
  background: ${green_secondary_color};
  padding: 10px 22px;
  color: ${background_color};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${green_main_color};
  }
`;