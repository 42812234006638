import React from 'react';
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { BrowserRouter as Link, useNavigate } from 'react-router-dom';

const RedShellMobile = () => {
    const navigate = useNavigate();

    const handleSwipe = (index) => {
        if (index === 0) {
        navigate('/classement-mobile');
        } else if (index === 2) {
        navigate('/red-shell-mobile');
        }
    };

    return (
        <SwipeableViews
        index={1}
        onChangeIndex={handleSwipe}
        style={{
            backgroundColor: '#082026',
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
        }}
        >
        <div
            style={{
            display: 'flex',
            justifyContent: 'center'}}>
            <img
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: window.innerHeight * 0.1,
                    width: window.innerWidth * 0.33,
                    height: window.innerWidth * 0.33,
                }}
                src="trophy.png"
                alt="trophy.png"
            />
        </div>
        <div style={{ width: '100%', backgroundColor: 'green' }}>
            <h1>Page GreenShellMobile</h1>
            <Link to="/classement-mobile">Retour à ClassementMobile</Link>
            <Link to="/red-shell-mobile">Aller à RedShellMobile</Link>
        </div>
        <div style={{ width: '100%', backgroundColor: 'blue' }}>
            <h1>Page RedShellMobile</h1>
            <Link to="/classement-mobile">Retour à ClassementMobile</Link>
            <Link to="/green-shell-mobile">Aller à GreenShellMobile</Link>
        </div>
        </SwipeableViews>
    );
};
export default RedShellMobile;