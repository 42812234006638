import React from "react";
import { PageBody } from "./components/C_Page";
import { DetailsMessage, ErrorMessage } from "./components/C_Error";
import { Helmet } from 'react-helmet';

const Error404 = () => {
    return (
        <>
            <Helmet>
                <title>Error 404 - Shell Society</title>
            </Helmet>
            <PageBody>
                <ErrorMessage>ERROR 404</ErrorMessage>
                <DetailsMessage>Page does not exist. Verify your url.</DetailsMessage>
            </PageBody>
        </>
    );
};

export default Error404;