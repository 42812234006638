import styled from "styled-components";

export const Image = styled.img` 
    padding: 0.5rem;
    border-radius: 100%;
`;

export const ClickableImage = ({source, link}) => {
    const handleClick = () => {
        window.location.href = link;
    }

    return (
        <Image
            src={"/" + process.env.PUBLIC_URL + source}
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
        />
    );
}